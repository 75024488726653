import { datatypes } from './datatypes';
import { types } from './types';
import i18n from '@/i18n/i18n';
import { ClassDxLookup } from './ClassDxLookup';

export class ClassColumnDataTable {
    public datafield!: string;
    public caption!: string;
    public datatype!: datatypes;
    public format: types;
    public visible!: boolean;
    public Required: boolean = false;
    public maxLength !: number;
    public alignment: datatypes = datatypes.left;
    public Lookup:ClassDxLookup=new ClassDxLookup();
    public formatString:string='';

    constructor(datafield: string, caption: string, datatype: datatypes, visible: boolean,maxLength:number) {
        this.datafield = datafield;
        this.caption = caption;
        this.datatype = datatype;
        this.visible = visible;
        this.format = "";
        this.maxLength=maxLength;
        if(datatype===datatypes.boolean){
            this.alignment= datatypes.center;
        }
    }
    public isRequired(Required: boolean=true){
        this.Required = Required;
        return this;
    }
    public ChangeAlignment(alignment: datatypes){
        this.alignment = alignment;
        return this;
    }
    public CreateDxLookup(DataSource: any,DisplayExpr: string,ValueExpr: string="id"){
        this.Lookup.DataSource = DataSource;
        this.Lookup.DisplayExpr = DisplayExpr;
        this.Lookup.ValueExpr = ValueExpr;
        return this;
    }
    public CreateFromatString(Format:string){
        this.formatString = Format;
        return this;
    }

}