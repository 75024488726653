















































































































































import {
  Component,
  Vue,
  Prop,
  Ref,
  PropSync,
  Watch,
} from "vue-property-decorator";

import {
  DxDataGrid,
  DxColumn,
  DxButton,
  DxPaging,
  DxEditing,
  DxLookup,
  DxPosition,
  DxForm,
  DxGroupPanel,
  DxSearchPanel,
  DxHeaderFilter,
  DxFormItem,
  DxExport,
  DxGrouping,
  DxFilterRow,
  DxValidationRule,
  DxPager,
  DxSummary,
  DxTotalItem,
  DxColumnChooser,
  DxStateStoring,
  DxPopup,
  DxScrolling,
  DxRowDragging,
} from "devextreme-vue/data-grid";
import "devextreme-vue/date-box";
import { DxItem } from "devextreme-vue/form";
import { ClassColumnDataTable } from "./ClassColumnDataTable";
import { ClassTypeSelectionRow } from "./ClassTypeSelectionRow";
import {
  EventAddClick,
  EventEditClick,
  EventRemoveClick,
  EventRefresh,
  EventEditingStart,
  EventCreatingStart,
  EventEditorPreparing,
  EventOnContextMenuPreparing,
  EventItemReordenado,
  EventBotonGenerico,
  EventCellClick
} from "./data-table-events";
import i18n from "@/i18n/i18n";
import { UtilsString } from "@/utils/utils-string";
import { UtilsBoolean } from "@/utils/utils-boolean";
import { datatypes } from "./datatypes";
import { VueInstanceService } from "@/shared/services/vue-instance-service";
import { GlobalEvents } from "@/shared/GlobalEvents";
import { EventoTeclado } from "@/shared/classes/EventoTeclado";
import { KeyboardKeyCodes } from "@/utils/keyboardKeyCodes";
import { Enlace } from "@/shared/dtos/data_table_generico/Enlace";
import { RouterNames } from "@/router/routernames";

@Component({
  components: {
    DxDataGrid,
    DxColumn,
    DxButton,
    DxPager,
    DxPaging,
    DxEditing,
    DxLookup,
    DxPosition,
    DxForm,
    DxGroupPanel,
    DxSearchPanel,
    DxHeaderFilter,
    DxFormItem,
    DxExport,
    DxItem,
    DxGrouping,
    DxFilterRow,
    DxValidationRule,
    DxSummary,
    DxTotalItem,
    DxColumnChooser,
    DxStateStoring,
    DxPopup,
    DxRowDragging,
    DxScrolling,
  },
})
export default class DataTable extends Vue {
  @Ref("gridRefName") VisualDxDataGrid!: DxDataGrid;
  @Prop({
    default: "id",
    type: String,
    required: false,
  })
  identificador!: string;
  @Prop({
    default: 20,
    type: Number,
    required: false,
  })
  pagesize!: string;
  @Prop({
    default: false,
    type: Boolean,
    required: false,
  })
  AllowExportSelectedData!: boolean;
  @Prop({
    default: true,
    type: Boolean,
    required: false,
  })
  HighlightCaseSensitive!: boolean;

  @Prop({
    default: true,
    type: Boolean,
    required: false,
  })
  showBorders!: string;

  @PropSync("DataSource", {
    required: true,
  })
  Datos!: any;

  @Prop({
    required: true,
  })
  Columns!: ClassColumnDataTable[];
  @Prop({
    required: false,
    default: false,
  })
  AllowReordering!: boolean[];
  @Prop({
    required: false,
  })
  OnReorderMetodo!: (...args: any[]) => void;
  @Prop({
    required: false,
    default: function defaultselection() {},
  })
  onSelectionChanged!: (...args: any[]) => void;
  @Prop({
    required: false,
    default: "",
  })
  CampoOrden!: string;
  @Prop({
    required: false,
    default: 173,
  })
  LessHeight!: number;

  @Prop({
    required: false,
    default: 0,
  })
  AdditionalHeight!: number;

  @Prop({
    required: false,
    default: "auto",
  })
  MinHeight!: number | string;

  @Prop({
    required: false,
    default: ClassTypeSelectionRow.Single,
  })
  tipo_seleccion!: string;

  @Prop({
    required: false,
    default: "",
  })
  SortTitle!: string;

  @Prop({
    required: false,
    default: false,
  })
  ocultarRefreshButton!: boolean;

  @Prop({
    required: false,
    default: true,
  })
  allowColumnReordering!: boolean;

  @Prop({
    required: false,
    default: "",
  })
  textoBotonGenerico!: string;

  @Prop({
    required: false,
    default: "",
  })
  iconoBotonGenerico!: string;

  @Prop({
    required: false,
    default: false,
  })
  mostrarBotonGenerico!: boolean;

  @Prop({
    required: false,
    default: "id",
  })
  keyExpresion!: string;

  @Prop({
    required: false,
    default: true,
  })
  Export!: boolean;

  @Prop({
    required: false,
    default: true,
  })
  GroupPanel!: boolean;

  @Prop({
    required: false,
    default: "",
  })
  Enlaces!: Enlace[];

  private popupVisible: boolean = false;
  private focusedRowKey: number = 0;
  private focusedRowIndex: number = 0;

  private showDialog: boolean = false;
  private PopUpKey: number = 1;
  private rowSelected!: number;

  private checkboxValue: boolean = false;
  private tieneEnlaces: boolean = this.Enlaces.length > 0;

  public created() {
    this.syncOrden();
  }

  private customValidationCallback(event: any): boolean {
    event.rule.isValid = false;
    event.rule.message = i18n.t("valornovalido") + event.column.caption;
    for (let _i = 0; _i < this.Columns.length; _i++) {
      if (event.column.dataField == this.Columns[_i].datafield) {
        if (this.Columns[_i].Required) {
          let valido: boolean = false;
          try {
            valido = event.value.toString().trim() !== "";
          } catch (error) {}

          event.rule.isValid = valido;
          if (!valido) {
            event.rule.message = i18n.t("valornovalido") + event.column.caption;
          } else {
            event.rule.message = "";
          }
          return valido;
        }
        if (
          this.Columns[_i].Lookup.ValueExpr === "" &&
          this.Columns[_i].datatype === datatypes.number
        ) {
          let valor = UtilsString.ValueOf(event.value);
          if (valor.length > this.Columns[_i].maxLength) {
            event.rule.isValid = false;
            event.rule.message = i18n.t("valor_largo", [
              event.column.caption,
              this.Columns[_i].maxLength.toString(),
              UtilsString.ValueOf(valor.length),
            ]);
          } else {
            event.rule.isValid = true;
            event.rule.message = "";
          }
          return event.rule.isValid;
        }
      }
    }
    return true;
  }

  private EventoBotonGenerico() {
    this.$emit(EventBotonGenerico);
  }

  private nuevaRow(e: any) {
    this.$emit(EventAddClick, e);
  }

  private EliminandoRow(e: any) {
    e.cancel = true;
    this.$emit(EventRemoveClick, e);
  }

  private UpdateRow(e: any) {
    this.$emit(EventEditClick, e);
  }
  private AbroPopup(e: any) {
    this.$emit(EventEditingStart, e);
  }
  private AbroPopupParaCrear(e: any) {
    this.$emit(EventCreatingStart, e);
  }
  private Refrescar() {
    this.$emit(EventRefresh);
  }

  private CambiarEstadoFiltro(e: any) {
    let botonClass: string = e.element.attributes[0].nodeValue;

    if (this.$route.params.filtroBloqueado === "true") {
      botonClass = botonClass.replace(" button-filter-active", "");
      this.$route.params.filtroBloqueado = "ToFalse";
    } else if (this.$route.params.filtroBloqueado !== undefined) {
      botonClass += " button-filter-active";

      this.$route.params.filtroBloqueado = "true";
    }
    e.element.attributes[0].nodeValue = botonClass;
  }

  private AñadirEstiloFiltroBloqueado(e: any) {
    if (this.$route.params.filtroBloqueado === "true") {
      let botonClass: string = e.element.attributes[0].nodeValue;
      botonClass += " button-filter-active";
      e.element.attributes[0].nodeValue = botonClass;
    }
  }

  private editorPreparing(e: any) {
    this.$emit(EventEditorPreparing, e);
  }
  private onContextMenuPreparing(e: any) {
    if (e.target === "content" && e.rowIndex >= 0 && e.row.rowType === "data") {
      //No eliminar así sabemos como crear un menu conceptual al hacer click derecho en una row
      /*e.items = [
        {
          text: i18n.t("addnuevafila"),
          icon: "add",
          onItemClick: function() {
            e.component.addRow(e.row.values);
          }
        },
        {
          text: i18n.t("editar") + " " + i18n.t("fila") + " " + e.rowIndex,
          icon: "edit",
          onItemClick: function() {
            e.component.editRow(e.row.rowIndex);
          }
        },

        {
          text: i18n.t("eliminar") + " " + i18n.t("fila") + " " + e.rowIndex,
          icon: "trash",
          onItemClick: function() {
            e.component.deleteRow(e.row.rowIndex);
          }
        }
      ];*/
      this.$emit(EventOnContextMenuPreparing, e);
    }
  }

  private linkIconClick(e: any) {
    this.rowSelected = e.row.key;
    this.PopUpKey += 1;
    this.showDialog = true;
    e.event.preventDefault();
  }

  private AbrirEnlace(enlace: Enlace) {
    this.$router.push({
      name: enlace.routerName,
      params: {
        pantallaGenerada: "SI",
        metodoCarga: enlace.from,
        id: this.rowSelected.toString(),
        filtroBloqueado: this.checkboxValue.toString(),
      },
    });
    VueInstanceService.vueinstance.$emit(GlobalEvents.CambiarRutaBreadCrumbs, enlace.routerLink);
    this.showDialog = false;
  }

  private onRowDblClick(e: any) {
    this.$emit(EventEditingStart, e);
  }

  private onRowClick(e: any) {
    if (this.tipo_seleccion === ClassTypeSelectionRow.Multiple) {
    }
  }

  private onToolbarPreparing(e: any) {
    if (!this.ocultarRefreshButton) {
      e.toolbarOptions.items.unshift(
        /*{
        location: "before",
        widget: "dxButton",
        options: {
          text: i18n.t("limpagru"),
          onClick: this.GetRefGrid.bind(this),
        },
      },*/
        {
          location: "after",
          widget: "dxButton",
          options: {
            icon: "refresh",
            text: "", //i18n.t("refresh"),
            onClick: this.Refrescar,
          },
        }
      );
    }
    if (this.$route.params.filtroBloqueado !== undefined) {
      e.toolbarOptions.items.unshift({
        location: "after",
        widget: "dxButton",
        options: {
          icon: "filter",
          text: "", //i18n.t("refresh"),
          onClick: this.CambiarEstadoFiltro,
          onContentReady: this.AñadirEstiloFiltroBloqueado,
        },
      });
    }
    if (this.mostrarBotonGenerico) {
      e.toolbarOptions.items.unshift({
        location: "center",
        widget: "dxButton",
        options: {
          icon: this.iconoBotonGenerico,
          text: this.textoBotonGenerico, //i18n.t("refresh"),
          onClick: this.EventoBotonGenerico,
          type: "success",
          stylingMode: "outlined",
        },
      });
    }
  }
  private onEditorPreparing(e: any) {
    //https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxForm/Item_Types/SimpleItem/#editorOptions
    if (e.parentType == "dataRow") {
      for (let _i = 0; _i < this.Columns.length; _i++) {
        if (e.dataField == this.Columns[_i].datafield) {
          e.editorOptions.maxLength = this.Columns[_i].maxLength;
        }
      }
    }
  }
  public GetRefGrid(e: any) {
    let Grid = this.$refs.gridRefName as any;
    Grid = Grid.instance;
    Grid.clearGrouping();
  }
  public RowKey(): string {
    let dtg: any = this.DxDataGrid;
    let rowKey = dtg.focusedRowKey;
    return UtilsString.ValueOfValue(dtg.instance.getKeyByRowIndex(rowKey));
  }

  public RowIndex(): string {
    let dtg: any = this.DxDataGrid;
    let rowIndex = dtg.focusedRowIndex;
    return UtilsString.ValueOfValue(dtg.instance.getKeyByRowIndex(rowIndex));
  }

  public RowTrueIndex(): string {
    let dtg: any = this.DxDataGrid;
    let rowIndex = dtg.focusedRowIndex;
    return rowIndex;
  }

  @Watch("Datos")
  private syncOrden() {
    if (
      this.AllowReordering &&
      !UtilsString.IsNullOrWhiteSpace(this.CampoOrden)
    ) {
      for (let i = 0; i < this.Datos.length; i++) {
        this.Datos[i][this.CampoOrden] = i;
      }
    }
  }
  private onReorder(e: any) {
    if (this.OnReorderMetodo !== undefined) {
      this.OnReorderMetodo({ componente: this, evnt: e });
    } else {
      if (UtilsString.IsNullOrWhiteSpace(this.CampoOrden)) {
        alert("No se ha pasado la propiedad :CampoOrden");
        throw new Error("No se ha pasado la propiedad :CampoOrden");
        return;
      }
      var visibleRows = e.component.getVisibleRows(),
        toIndex = this.Datos.indexOf(visibleRows[e.toIndex].data),
        fromIndex = this.Datos.indexOf(e.itemData);
      this.Datos.splice(fromIndex, 1);
      this.Datos.splice(toIndex, 0, e.itemData);
      for (let i = 0; i < this.Datos.length; i++) {
        this.Datos[i][this.CampoOrden] = i;
      }
      this.$emit(EventItemReordenado, e);
    }
  }

  private get displayFormatSummary() {
    return i18n.t("numregistros") + ": {0}";
  }
  private get DxDataGrid() {
    return this.VisualDxDataGrid;
  }
  private get pageSizes() {
    return [10, 20, 40, 60, 80, 100];
  }
  private get ShowSumaryIn() {
    for (let i = 0; i < this.Columns.length; i++) {
      if (this.Columns[i].visible) {
        return this.Columns[i].datafield;
      }
    }
  }
  private get IsProduction(): boolean {
    return UtilsBoolean.Anytoboolean(process.env.VUE_APP_PRODUCCION);
  }
  private get getstyle() {
    let addnum = this.MinHeight;
    let num =
      this.$vuetify.breakpoint.height - this.LessHeight - this.AdditionalHeight;
    if (this.MinHeight === "auto") {
      addnum = num;
    }
    return (
      "max-height:" +
      num +
      "px;min-height:" +
      addnum +
      "px; overflow-x: hidden; overflow-y: auto;"
    );
  }

  public onCellClick(e:any){
    this.$emit(EventCellClick, e);
  }
}
