export const EventAddClick: string = 'EventAddClick';
export const EventEditClick: string = 'EventEditClick';
export const EventRemoveClick: string = 'EventRemoveClick';
export const EventRefresh: string = 'EventRefresh';
export const EventEditingStart: string = 'EventEditingStart';
export const EventCreatingStart: string = 'EventCreatingStart';
export const EventEditorPreparing: string = 'EventEditorPreparing';
export const EventOnContextMenuPreparing:string = 'EventOnContextMenuPreparing';
export const EventItemReordenado:string = 'EventItemReordenado';
export const EventBotonGenerico:string = 'EventBotonGenerico';
export const EventCellClick:string = 'EventCellClick';